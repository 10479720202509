<template>
  <div class="d-flex flex-column flex-root" data-app>
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto bgi-size-cover bgi-position-y-bottom bgi-position-x-center"
        :style="{ backgroundImage: `url(${asideBackgroundImage})` }"
      >
        <div class="h-100 d-flex align-items-start pt-25 px-25 text-white">
          <div>
            <div>
              <router-link to="/">
                <img src="/media/logos/logo.svg" class="h-48px" />
              </router-link>
            </div>
            <p class="mt-18 mb-9 font-weight-bolder font-size-h1">
              {{ asideTitle }}
            </p>
            <p class="font-size-h4">
              {{ asideDescription }}
            </p>
          </div>
        </div>
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <router-view
            @layout-login-aside-background="changeAsideBackground"
            @layout-login-aside-title="changeAsideTitle"
            @layout-login-aside-description="changeAsideDescription"
          />
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/auth/LayoutLogin.scss";
</style>

<script>
import { mapGetters } from "vuex";
import HtmlClass from "@/core/services/htmlclass.service";

export default {
  name: "LayoutLogin",
  data() {
    return {
      asideBackgroundImage: process.env.BASE_URL + "media/bg/bg-2.jpg",
      asideTitle: "",
      asideDescription: ""
    };
  },
  beforeMount() {
    // Initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // Check if current user is already authenticated
    if (this.isAuthenticated) {
      this.$router.push({ name: "Home" });
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "layoutConfig"]),

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    }
  },
  methods: {
    changeAsideBackground(newBackground) {
      this.asideBackgroundImage = newBackground;
    },
    changeAsideTitle(newTitle) {
      this.asideTitle = newTitle;
    },
    changeAsideDescription(newDescription) {
      this.asideDescription = newDescription;
    }
  }
};
</script>
